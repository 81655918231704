var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticStyle: { "margin-top": "9px" }, attrs: { cols: "12" } },
        [_c("ConfigNavBar")],
        1
      ),
      _c("v-col", { attrs: { cols: "6" } }, [
        _c(
          "h2",
          {
            staticClass: "title titleColor--text",
            staticStyle: {
              "font-size": "32px !important",
              "font-weight": "700",
              "margin-top": "18px",
            },
          },
          [_vm._v("Configurações")]
        ),
      ]),
      _c("v-col", { attrs: { cols: "6" } }, [
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "v-btn",
              {
                attrs: { rounded: "", color: "primary", id: "add-btn" },
                on: { click: _vm.openModalNewMessageRule },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "max-width": "1px",
                      "max-height": "1px",
                      left: "-12px",
                    },
                  },
                  [_vm._v("mdi-plus")]
                ),
                _vm._v(" Adicionar "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-col",
        { staticStyle: { "margin-top": "-12px" }, attrs: { cols: "6" } },
        [
          _c("p", { staticClass: "subtitle" }, [
            _vm._v("Regra de mensagens que devem ser enviadas automaticamente"),
          ]),
        ]
      ),
      _c("v-col", { attrs: { cols: "6" } }),
      _c("ModalNewMessageRule", { ref: "modalNewMessageRule" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }