<template>
  <v-row>
    <v-col cols="12" style="margin-top: 9px"> <ConfigNavBar /> </v-col>
    <v-col cols="6">
      <h2 class="title titleColor--text" style="font-size: 32px !important; font-weight: 700; margin-top: 18px">Configurações</h2>
    </v-col>
    <v-col cols="6">
      <div class="float-right">
        <v-btn @click="openModalNewMessageRule" rounded color="primary" id="add-btn">
          <v-icon style="max-width: 1px; max-height: 1px; left: -12px;">mdi-plus</v-icon> Adicionar
        </v-btn>
      </div>
    </v-col>
    <v-col cols="6" style="margin-top: -12px">
      <p class="subtitle">Regra de mensagens que devem ser enviadas automaticamente</p>
    </v-col>
    <v-col cols="6"> </v-col>
    <ModalNewMessageRule ref="modalNewMessageRule" />
  </v-row>
</template>
<script>
export default {
  name: 'HeaderMessage',
  components: {
    ConfigNavBar: () => import('./ConfigNavBar.vue'),
    ModalNewMessageRule: () => import('./ModalNewMessageRule.vue'),
  },
  methods: {
    openModalNewMessageRule() {
      this.$refs.modalNewMessageRule.$emit('upsert');
    },
  },
};
</script>
<style>
.title {
  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}
.subtitle {
  height: 24px;
  width: 921px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
}
.btn-config {
  height: 48px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  backgound-color: var(--v-primary-base) !important;
  color: #fff !important;
}

#add-btn {
  width: 171px;
  margin-top: 16px;
}
#add-btn :hover {
  background-color: #e3ffee;
  width: 178px;
  height: 39px;
  border-radius: 5px;
  color: var(--v-primary-base);
}
</style>
